export const setSession = async (usuario) => {
  sessionStorage.setItem("token", JSON.stringify(usuario));
};

export const getSession = () => {
  return JSON.parse(sessionStorage.getItem("token"));
};
export const getItemSession = (item) => {
  let usuario = JSON.parse(sessionStorage.getItem("token"));
  return usuario !== null ? usuario[item] : null;
};

export const clearSession = () => {
  sessionStorage.clear();
};

function recargaUrl() {
  return sessionStorage.getItem("token") &&
    JSON.parse(sessionStorage.getItem("token"))["ruc"] === "80091082-6"
    ? "https://www.brunos.siga.com.py/FacturaE2/"
    : document.URL.match(/localhost*/)
    ? "http://localhost:8080/Factura_Electronica/"
    : "https://facte.siga.com.py/FacturaE/";
}

export const MainUrl =
  sessionStorage.getItem("token") &&
  JSON.parse(sessionStorage.getItem("token"))["ruc"] === "80091082-6"
    ? "https://www.brunos.siga.com.py/FacturaE2/"
    : document.URL.match(/localhost*/)
    ? "http://localhost:8080/Factura_Electronica/"
    : "https://facte.siga.com.py/FacturaE/";

// export const MainUrl2 = "https://brunos.expertapp360.com/FacturaE2/";
export const MainUrl2 = "https://www.brunos.siga.com.py/FacturaE2/";
